$couleur_principale : #fff;
$couleur_secondaire: #d5b791;
$couleur_bordures:#e4e4e4;

/* Général */
$body_background_color : #fff;
$texte_lien:$couleur_secondaire;
$texte_lien_hover:lighten($texte_lien,10%);
$header_background:$couleur_principale;
$slogan_color_texte:$couleur_secondaire;
$titre_color:#000;
$border_titre_color:1px solid $couleur_secondaire;
$texte_color:#000;

/* Menu */
$menu_background_color:#fff;
$menu_border:1px solid $couleur_bordures;
$nav_background_color:transparent;
$nav_texte_color:#000;
$nav_texte_color_hover:$couleur_secondaire;
$nav_texte_color_sous:#000;
$nav_texte_color_sous_hover:$couleur_secondaire;
$li_background_color:#fff;
$li_background_sous_color:#fff;
$li_background_color_hover:#fff;
$li_background_color_sous_hover:#fff;
$icone_color:#e4e4e4;
$icone_color_hover:$couleur_secondaire;
/* Navbar */
$border_navbar:1px solid $couleur_bordures;
$border_navbar_li:1px dashed $couleur_bordures;
$color_link_navbar:#000;

/* Menu responsive */
$burger_background_color:#000;
$burger_texte_color:$couleur_principale;

/* Produits */
$mask_background:$couleur_secondaire;
$mask_lien_color:#fff;
$etiquette_produit_background:$couleur_secondaire;
$etiquette_produit_texte:#fff;
$remise_background_color:$couleur_secondaire;
$remise_texte_color:#fff;
$background_produit_accueil:rgba(0,0,0,.1);
$background_produit:$couleur_principale;
$border_addbasket:1px solid $couleur_bordures;
$background_addbasket:#fff;
$color_addbasket:$couleur_secondaire;
$background_addbasket_hover:#f3f3f3;
$color_addbasket_hover:#000;
$border_produits:1px solid $couleur_bordures;
$texte_produits:#000;
$texte_titre_produits:$couleur_secondaire;

/* Fiche produit */
$fiche_produit_border:1px solid $couleur_bordures;

/* Slider */
$background_texte:rgba(255,255,255,.9);
$slider_color_link:$couleur_secondaire;
$slider_color:#000;

/* Galerie photo*/
$galerie_texte_color:#000;

/* Livre d'or */
$message_lo_color:$couleur_principale;

/* Blog / Boutons */
$btn_background_color:$couleur_principale;
$btn_texte_color:#000;

/* Slider */
$btn_background_next:rgba(255,255,255,.8);
$btn_background_next_hover:rgba(255,255,255,1);
$btn_color_next:#000;

/* Footer */
$footer_background_color:$couleur_principale;
$footer_texte_color:#000;
$footer_border-color:1px solid $couleur_bordures;
$footer_link_color:$texte_lien;

/* Colonne de gauche */
$colonne_gauche_border:$couleur_principale;

/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:#d5b791;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/773series/declinaison";